// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import ContactsBlock from '../../components/basic/ContactsBlock'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../IntroTeaser'
import ClientOverview from '../Clients/ClientOverview'
import ContactForm from './ContactForm'

import messages from './ContactPage.lang'
import baseStyles from './ContactPage.scss'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
  isITB?: boolean,
  ...StyleProps
}

const IntroImage = (
  <StaticImage src="../../images/pictures/contact_intro_image.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/contact_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const ContactPage = ({ styles, isITB }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser
        title={formatMessage(isITB ? messages.pageTitleITB : messages.pageTitle)}
        image={HeroTeaserImage}
        className={styles.heroTeaser}
        imgAlt="office gallery"
        aside={
          <div>
            <ContactForm
              formClassName={'contactMain'}
              formHeadline={isITB && messages.formHeadlineITB}
              formButtonLabel={isITB && locale === 'de' && formatMessage(messages.formButtonLabel)}
            />
          </div>
        }
      />
      <Container fluid>
        <ClientOverview />
      </Container>
      <Container fluid>
        <IntroTeaser
          title={formatMessage(messages.introTitle)}
          copy={
            <>
              <p>{formatMessage(messages.introInfo)}</p>
              <ContactsBlock />
            </>
          }
          image={IntroImage}
          styles={styles.intro}
        />
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ContactPage)
