// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import messages from './ContactsBlock.lang'
import baseStyles from './ContactsBlock.scss'

type Props = {
  ...StyleProps
}

const ContactsBlock = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <div className={styles.companyName}>{formatMessage(messages.name)}</div>
      <div className={styles.contactsWrap}>
        <div className={styles.address}>
          <FormattedMessageSanitized {...messages.address} />
        </div>
        <ul className={styles.list}>
          <li className={styles.item}>
            <a href="tel: +49 (0) 211 933 01 10" target="_blank" rel="noopener noreferrer" className={styles.link}>
              <span className={classNames(styles.linkIcon, 'icon-phone')} />
              +49 (0) 211 933 01 10
            </a>
          </li>
          <li className={styles.item}>
            <a href="tel: +49 (0)211 933 01 60" target="_blank" rel="noopener noreferrer" className={styles.link}>
              <span className={classNames(styles.linkIcon, 'icon-fax')} />
              +49 (0)211 933 01 60
            </a>
          </li>
          <li className={styles.item}>
            <a href="mailto: post@freshcells.de" target="_blank" rel="noopener noreferrer" className={styles.link}>
              <span className={classNames(styles.linkIcon, 'icon-email')} />
              post@freshcells.de
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ContactsBlock)
