import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Request <b>free</b><br/><b>advice</b> for your<br/>project now',
  pageTitleITB: 'Make an <b>appointment</b><br/>for <b>ITB 2024</b> now',
  introTitle: 'Let’s talk',
  introInfo: 'Do you have any questions ? About one of our client projects? Or a vacancy? Or are you looking for an e - commerce solution ? Don\'t hesitate to contact us. We look forward to your questions.',
  title: 'Request <b>free{br} advice</b> for your{br} project now',
  formHeadlineITB: 'We look forward to meeting you! Make an <b>appointment</b> now',
  formButtonLabel: 'Send request now'
})
